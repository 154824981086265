

import { useEffect, useState } from 'react';
import { Input, Button, Image, Space } from 'antd';

import { requestData } from './request';

export function ImageSearch(props) {

  const [images, setImages] = useState([]);
  const [query, setQuery] = useState(props.query);
  const [loading, setLoading] = useState(false);

  async function loadData(query) {
    setLoading(true);
    try {
      const data = await requestData('searchImage', {
        query
      });

      setImages(data);
    } catch (e) {
      
    } finally {
      setLoading(false);
    }
  }

  let imageRes = [];

  for (const image of images) {
    imageRes.push(
      <RenderImage
        onChoose={() => {
          props.onChoose(image);
        }}
        image={image}
      />
    );
  }

  function onQueryChange(e, arg2, arg3) {
    setQuery(e.target.value);
  }

  function onSearch() {
    loadData(query);
  }

  return (
    <div>
      <div className='image-header'>
        <Space direction='horizontal'>
          <span>query:</span>
          <Input width={400} value={query} onChange={onQueryChange}></Input>
          <Button loading={loading} onClick={onSearch}>search</Button>
        </Space>
      </div>
      <div className='image-list'>
        { imageRes }
      </div>
    </div>
  )
}

function RenderImage({image, onChoose}) {
  return (
    <div className='image-item'>
      <span className='image-tip'>
        {image.width}x{image.height}
        <Button onClick={onChoose} type='link'>choose</Button>
      </span>
      <Image width={300} src={image.link} />
    </div>
  )
}
