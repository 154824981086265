
import React, { useEffect, useState } from 'react';

import { Tabs, Spin } from 'antd';
import * as moment from 'moment';

import { requestData } from './request';

import { Line } from '@ant-design/charts';

function RenderSite(props) {
  const data = props.data;

  const [playData, setPlayData] = useState([]);
  const [subscribeData, setSubscribeData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [shareData, setShareData] = useState([]);
  const [finishRateData, setFinishRateData] = useState([]);

  useEffect(() => {
    const newPlayData = [];
    const newSubscribeData = [];
    const newCommentData = [];
    const newShareData = [];
    const newFinishRateData = [];

    for (const item of data) {
      let { gmtCreate, showType, totalPlay, commonNum, finishRate, shareNum, subscribeNum } = item;
      gmtCreate = moment(gmtCreate).format('YYYY-MM-DD HH:mm:ss');
      newPlayData.push({
        gmtCreate,
        showType,
        value: totalPlay
      });
      newSubscribeData.push({
        gmtCreate,
        showType,
        value: subscribeNum
      });
      newCommentData.push({
        gmtCreate,
        showType,
        value: commonNum
      });
      newShareData.push({
        gmtCreate,
        showType,
        value: shareNum
      });
      newFinishRateData.push({
        gmtCreate,
        showType,
        value: finishRate * 100
      });
    }

    setPlayData(newPlayData);
    setSubscribeData(newSubscribeData);
    setCommentData(newCommentData);
    setShareData(newShareData);
    setFinishRateData(newFinishRateData);

  }, []);

  return (
    <div>
      <h3>Play</h3>
      <div>
        <Line xField='gmtCreate' yField='value' data={playData} seriesField='showType' />
      </div>
      <h3>Subscribe</h3>
      <div>
        <Line xField='gmtCreate' yField='value' data={subscribeData} seriesField='showType' />
      </div>
      <h3>FinishRate</h3>
      <div>
        <Line xField='gmtCreate' yField='value' data={finishRateData} seriesField='showType' />
      </div>
      <h3>Comment</h3>
      <div>
        <Line xField='gmtCreate' yField='value' data={commentData} seriesField='showType'/>
      </div>
      <h3>Share</h3>
      <div>
        <Line xField='gmtCreate' yField='value' data={shareData} seriesField='showType' />
      </div>
    </div>
  )
}

export default () => {

  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const data = await requestData('getSiteData');
        const siteMap = {};
        for (const item of data) {
          let arr = siteMap[item.site] || [];
          arr.push(item);
          siteMap[item.site] = arr;
        }
  
        let newTabs = [];
        for (const site in siteMap) {
          newTabs.push({
            label: site,
            key: site,
            children: <RenderSite data={siteMap[site]}></RenderSite>
          });
        }
        setTabs(newTabs);
      } catch(e) {
      } finally {
        setLoading(false);
      }
    }

    loadData();

  }, []);

  return (
    <div>
      <Spin spinning={loading}>
        <Tabs items={tabs}></Tabs>
      </Spin>
    </div>
  )
}
