
import React, { useEffect, useState } from 'react';

import { requestData } from './request';
import { Descriptions, Progress, Button, message, Space, Table, Tag, Modal, DatePicker } from 'antd';
import moment from 'moment';

async function loadData() {
  const res = await requestData('getDailyPodCast');
  return res;
}

async function onEditTitle(item) {
  const title = window.prompt();
  await requestData('updatePodcastTitle', {
    title,
    dateTime: item.dateTime
  });
}

const columns = [{
  title: 'articleId',
  key: 'articleId',
  dataIndex: 'articleId'
}, {
  title: 'index',
  key: 'index',
  dataIndex: 'index',
}, {
  title: 'dateTime',
  key: 'dateTime',
  render: (item) => {
    const today = moment().format('YYYY-MM-DD');
    return (
      <span className={today === item.dateTime ? 'today': ''}>{item.dateTime}</span>
    )
  }
}, {
  title: 'title',
  key: 'title',
  dataIndex: 'title'
}, {
  title: 'dailyTitle',
  key: 'dailyTitle',
  dataIndex: 'dailyTitle'
}, {
  title: 'showNotes',
  key: 'showNotes',
  dataIndex: 'showNotes'
},{
  title: 'content',
  key: 'content',
  dataIndex: 'content',
}, {
  title: 'action',
  key: 'action',
  render: (item) => {
    return (
      <div>
        <Button type='link' onClick={() => { onEditTitle(item) }}>edit title</Button>
      </div>
    );
  }
}];

export default () => {
  
  const [listData, setListData] = useState([]);
  const [date, setDate] = useState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      data = data.reverse();
      setLoading(false);
      let i = 0;
      for (const item of data) {
        item.key = i++;
      }
      setListData(data);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  }, []);

  async function onGenerate() {
    await requestData('generateAudioCXDT', {
      datetime: date.format('YYYY-MM-DD')
    });

    message.success('success');
  }

  function onDateChange(val) {
    console.log(val);
    setDate(val);
  }

  return (
    <div className="daily-podcasts">
      <DatePicker value={date} onChange={onDateChange} />
      <Button onClick={onGenerate}>Generate</Button>
      <Table loading={loading} dataSource={listData} columns={columns} pagination={false} />
    </div>
  );
}
