
import { Input, Button, message, Space, Tabs, Table, Tag, Modal } from 'antd';

import { useState, useEffect, useCallback } from 'react';
import { requestData } from './request';

function RenderCNContent({item}) {
  const [content, setContent] = useState(item.cnContent);
  async function onUpdateCnContent() {
    await requestData('updateSegment', {
      cnContent: content,
      audioId: item.audioId,
      segmentId: item.segmentId
    });
  }

  async function onRebuildSegment() {
    await requestData('rebuildSegment', {
      audioId: item.audioId,
      segmentId: item.segmentId
    });
  }

  return (
    <>
      <Button type="primary" onClick={onUpdateCnContent}>update</Button>
      <Button type="primary" onClick={onRebuildSegment}>rebuild</Button>
      <Input.TextArea className='audio-cn-content-textarea' value={content} onChange={(e) => setContent(e.currentTarget.value)} />
    </>
  );
}

export function Segment({ audioId }) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await requestData('getAudioSegment', {
        audioId
      });
      setData(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  async function onRebuildAudio() {
    requestData('rebuildAudio', { audioId });
  }

  return (
    <div>
      <Button type='primary' onClick={onRebuildAudio}>Rebuild Audio</Button>
      <Table loading={loading} pagination={false} dataSource={data} columns={[{
          title: 'cnContent',
          key: 'cnContent',
          render: (item) => {
            return <RenderCNContent item={item} />
          }
        }, {
          title: 'enContent',
          key: 'enContent',
          dataIndex: 'enContent'
        }, {
          title: 'ossPath',
          key: 'ossPath',
          dataIndex: 'ossPath'
        }]
      }></Table>
    </div>
  );

}
