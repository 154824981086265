
import React, { useEffect, useState } from 'react';

import { requestData } from './request';
import { NodeIndexOutlined } from '@ant-design/icons';
import { Descriptions, Progress, Button, message, Space, Tabs, Table, Tag, Modal } from 'antd';
import { Line } from '@ant-design/charts';
// import { partial } from 'filesize';
import fileSize from 'filesize';

import moment from 'moment';
import { Spin } from 'antd';

// const fileSize = partial({ standard: 'jedec' });

async function loadData() {
  const res = await requestData('getCXDTArticles');
  return res;
}

const columns = [{
  title: 'articleId',
  key: 'articleId',
  dataIndex: 'articleId'
}, {
  title: 'site',
  key: 'site',
  dataIndex: 'site'
}, {
  title: 'title',
  key: 'title',
  dataIndex: 'title'
}, {
  title: 'publishDate',
  key: 'publishDate',
  dataIndex: 'publishDate'
}, {
  title: 'action',
  key: 'action',
  render: (item) => {
    return (
      <div>
        <Button type='link' onClick={onChooseArticle.bind(undefined, item)}>choose</Button>
        <Button type='link' onClick={onGelintonghua.bind(undefined, item)}>onGelintonghua</Button>
        <Button type='link' onClick={onDeepThought.bind(undefined, item)}>onDeepThought</Button>
        <Button type='link' onClick={onTED.bind(undefined, item)}>onTED</Button>
      </div>
    )
  },
}];

async function onChooseArticle(record) {

  await requestData('addDailyArticle', {
    articleId: record.articleId,
  });

  message.success('添加成功');

}

async function onGelintonghua(item) {
  await requestData('generateAudioArticle', {
    articleId: item.articleId,
    type: 'gelintonghua'
  });
}

async function onDeepThought() {
  await requestData('generateAudioArticle', {
    articleId: item.articleId,
    type: 'deepThought'
  });
}

async function onTED() {
  await requestData('generateAudioArticle', {
    articleId: item.articleId,
    type: 'ted.com'
  });
}

function renderContent(record) {
  return <div>{record.content}</div>
}

function RenderList({ listData }) {
  return (
    <Table dataSource={listData} columns={columns} pagination={false} expandable={{
      rowExpandable: () => true,
      expandedRowRender: renderContent
    }} />
  );
}

export default () => {

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setLoading(false);
      let i = 0;
      const itemMap = {};
      for (const item of data) {
        item.key = i++;
        const { site } = item;
        if (itemMap[site] === undefined) {
          itemMap[site] = [];
        }
        itemMap[site].push(item);
      }
      const keys = Object.keys(itemMap);
      const newItems = [{
        key: 'all',
        label: 'All',
        children: <RenderList listData={data}></RenderList>
      }];
      for (const key of keys) {
        newItems.push({
          key: key,
          label: key,
          children: <RenderList listData={itemMap[key]}></RenderList>
        });
      }
      setItems(newItems);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  }, []);
  return (
    <div className="articles">
      <Spin spinning={loading}>
        <Tabs items={items}></Tabs>
      </Spin>
    </div>
  );
}
