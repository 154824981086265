import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider, theme } from 'antd';
import { createBrowserRouter, MemoryRouter, BrowserRouter, Outlet, RouterProvider, Route, Routes } from 'react-router-dom';

import App from './App';
import CXDTArticles from './cxdt-articles';
import Articles from './articles';
import DailyPodcasts from './daily-podcast';
import Audios from './audios';
import Podcast from './podcast';
import Task from './task';
import SiteData from './site-data';

const router = createBrowserRouter([
  {
    'path': '/',
    Component: App,
    children: [{
      path: 'articles',
      Component: Articles
    }]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{algorithm: theme.darkAlgorithm}}>
      <MemoryRouter>
        <Routes>
          <Route path="/" Component={App}>
            <Route path="cxdt-articles" Component={CXDTArticles}></Route>
            <Route path="articles" Component={Articles}></Route>
            <Route path="dailyPodcasts" Component={DailyPodcasts}></Route>
            <Route path="audios" Component={Audios}></Route>
            <Route path="podcast" Component={Podcast}></Route>
            {/* <Route path="task" Component={Task}></Route> */}
            <Route path="site-data" Component={SiteData}></Route>
            <Route path="" Component={Articles} />
          </Route>
        </Routes>
      </MemoryRouter>
    </ConfigProvider>
  </React.StrictMode>
);
