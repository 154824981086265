
import {
  HomeOutlined,
  EditOutlined,
  CloudServerOutlined,
  UserOutlined,
  GoldOutlined,
  SaveOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Avatar, Breadcrumb, Layout, Menu, Image } from 'antd';
import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import './App.css';

import { isAdmin } from './util';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('CXDT Articles', 'cxdt-articles', <Link to="cxdt-articles"><HomeOutlined /></Link>),
  getItem('Articles', 'articles', <Link to="articles"><HomeOutlined /></Link>),
  getItem('Daily Podcasts', 'dailyPodcasts', <Link to="dailyPodcasts"><HomeOutlined /></Link>),
  getItem('Audios', 'audios', <Link to="audios"><HomeOutlined /></Link>),
  getItem('Podcast', 'podcast', <Link to="podcast"><HomeOutlined /></Link>),
  // getItem('Task', 'task', <Link to="task"><HomeOutlined /></Link>),
  getItem('SiteData', 'site-data', <Link to="site-data"><HomeOutlined /></Link>)
];

const defaultKey = 'articles';
const App = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={[defaultKey]} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background site-header"
          style={{
            padding: 0,
          }}
        >
          <Avatar className="avatar" src={
            <Image src="http://cdn-pt.perterpon.com/html/logo.png"></Image>
          }></Avatar>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {/* {props.children} */}
            <Outlet></Outlet>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Powered By PTHelper
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;